@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Jost", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  @apply scroll-smooth;
}

body {
  height: 100%;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 300ms ease;
}

.underline-animation, .news-content a {
  position: relative;
  text-decoration: none;
}

.underline-animation::after, .news-content a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  transition: transform 0.25s ease-out;
  transform: scaleX(0);
}

.underline-animation:hover::after, .news-content a:hover::after {
  transform: scaleX(1);
}

* {
  box-sizing: border-box;
}

.heading {
  font-family: "Montserrat", sans-serif;
}

.banner {
  padding-top: 92px;
}

.btn-primary {
  @apply py-3 px-6 rounded-full transition-all duration-300;
}

.form-input {
  @apply mb-4 px-4 py-2 border border-neutral-400 bg-white rounded-[20px] transition-all;
}

.form-input:hover,
.form-input:focus-within {
  @apply border-black;
}

.form-input:focus-within {
  @apply outline-none;
}

.job-details section {
  @apply py-8
}

.job-details h2 {
  @apply text-3xl my-4
}

.job-details ul {
  @apply list-disc list-inside ml-4
}

.news-content p {
  @apply text-neutral-500 mb-4
}

.news-content a {
  color: #3892e7
}

.news-content a::after {
  background-color: #3892e7;
}

.news-content figure {
  margin-bottom: 1rem;
}

.news-content figure figcaption {
  @apply my-4 italic text-neutral-500
}

.news-content h5 {
  @apply font-bold text-xl mb-4
}

.news-content h6 {
  @apply font-bold text-black py-4
}

.news-content .video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.news-content .video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.scrollbar::-webkit-scrollbar {
  @apply w-2;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 3rem;
  border: 2px solid white;
}

.scrollbar::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 4px;
}

.scrollbar::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 4px;
}

.mobile-testing-slide {
  background-image: linear-gradient(to right, RGBA(15,47,100,1) 0%,RGBA(15,47,100,0.2) 100%);
}

.your-lab-animation {
  position: relative;
  text-decoration: none;
}

.your-lab-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  transform-origin: bottom left;
  animation-name: yourLabLine;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes yourLabLine {
  from {transform: scaleX(0);}
  to {transform: scaleX(1);}
}

@media screen and (max-width: 1024px) {
  .banner {
    padding-top: 68px;
  }
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    background-color: white;
    color: black;
  }

  input {
    background: white;
  }

  textarea {
    background: white;
  }
}
